import * as Sentry from '@sentry/browser';

function install() {
  Sentry.init({
    dsn: window.flatfoxConfig.sentry.dsn,
    release: window.flatfoxConfig.sentry.release,
    environment: window.flatfoxConfig.sentry.environment,
    ignoreErrors: [
      // Our javascript code is split up in a lot of different chunk files.
      // This unfortunately leads to loading errors on client browsers if e.g.
      // the internet connection is not stable enough. We could reduce the
      // number of chunks, but this would no really solve the problem. Therefore
      // we decided to just ignore this client error for now.
      /Loading chunk [\d]+ failed/,
      // This is similar to the chunk one above, a JS file we seem not to be
      // able to load, most probably because of network problems. The app will
      // crash (or fail to load), to be sure, but not much we can do about it.
      /gettext is not defined/,
    ],
    denyUrls: [
      // Filter out errors from OneTrust cookie banner.
      // They originate from the cookie widget's internals, which are not under our control,
      // and most of them are caused by flaky networks or content blockers.
      'https://cdn.cookielaw.org/',
    ],

    beforeSend(event) {
      // Sentry logs out weird stuff which is not produced by the flatfox app itself
      // but probably some browser plugins. Prevent sentry pollution by filtering them out
      // See Issue: https://github.com/flatfox-ag/flatfox-1website/issues/6021
      if (event?.exception?.values?.length === 1) {
        const { type, value } = event.exception.values[0];
        if (
          type === 'UnhandledRejection' &&
          value.indexOf('Object Not Found Matching Id') > -1
        ) {
          return null;
        }

        if (
          type === 'ReferenceError' &&
          value.indexOf('find variable: _AutofillCallbackHandler') > -1
        ) {
          // Seems to be an issue affecting only the Instagram in-app browser,
          // quietly being ignored by meta. It happens 100-200 times a day.
          // It does not reach the user.
          // Sentry: FLATFOX-WEBSITE-WEB-1CED
          // https://developers.facebook.com/community/threads/320013549791141/
          return null;
        }
      }
      return event;
    },
  });

  if (window.flatfoxConfig.user) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: window.flatfoxConfig.user.pk });
    });
  }
}

install();
